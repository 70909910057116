export default [
    {
        name: "OVERVIEW",
        icon: "chalkboard",
        to: "/master/main"
    },
    {
        name: "참여자 정보",
        icon: "address-card",
        children: [
            {
                name: "치료사 목록",
                to: "/master/therapistList"
            },
            {
                name: "환자 목록",
                to: "/master/patientsList"
            },
            {
                name: "미등록 환자 목록",
                to: "/master/nonePatientsList"
            },
        ],
    },
    {
        name: "평가 정보",
        icon: "file-alt",
        to: "/master/evaluationList"
    },
    {
        name: "수강 관리",
        icon: "photo-video",
        children: [
            {
                name: "교육 영상 관리",
                to: "/master/videoUpload"
            },
            {
                name: "표준화 가이드 영상 관리",
                to: "/master/videoStandard"
            },
            {
                name: "교육 영상 학습 이력",
                to: "/master/eduCheckList"
            },
            {
                name: "표준화 평가 이력",
                to: "/master/standardizationList"
            },
        ],
    },
    {
        name: "관리자 정보 관리",
        icon: "tools",
        to: "/master/masterInfo"
    },
    {
        name: "게시글 관리",
        icon: "book",
        children: [
            {
                name: "표준화 F&A",
                to: "/master/fna"
            },
            {
                name: "데이터 다운로드",
                to: "/master/dataDownload"
            },
            {
                name: "자주묻는 질문(환자)",
                to: "/master/faq"
            },
            {
                name: "공지사항(환자)",
                to: "/master/notice"
            },
        ],
    },
];
