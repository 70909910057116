<template>
  <div class="layout">
    <div class="modal-cover" v-show="isShowModal"></div>
    <mdb-side-nav-2
        :value="true"
        :data="navigation"
        slim
        :slim-collapsed="collapsed"
        @toggleSlim="collapsed = $event"
        sidenav-class="unique-color-dark"
        color="white"
        @itemClick="selectItem"
    >
      <div slot="header">
        <div class="p-1">
          <img
              src="@/assets/images/IA/master_img_logo.png"
              class="img-fluid"
          />
        </div>
        <hr/>
      </div>
      <div slot="content" class="mt-5 d-flex justify-content-center">
        <mdb-btn tag="a" class="mx-0" size="sm" transparent floating
                 :icon="collapsed ? 'chevron-right' : 'chevron-left'" icon-class="white-text"
                 @click="collapseLock"></mdb-btn>
      </div>

      <mdb-navbar
          slot="nav"
          tag="div"
          :toggler="false"
          position="top"
          dark
          color="unique-color-dark"
      >
        <mdb-navbar-nav class="nav-flex-icons" right>

          <mdb-nav-item waves-fixed icon="user">
            <span class="ml-1">{{email}}</span>
          </mdb-nav-item>
          <mdb-nav-item waves-fixed icon="sign-out-alt">
            <span class="ml-1" @click="isShowModal = true">로그아웃</span>
          </mdb-nav-item>
        </mdb-navbar-nav>
      </mdb-navbar>

      <div slot="main" class="main">
        <router-view class="main-contents"></router-view>
      </div>
    </mdb-side-nav-2>
    <LogoutDialog v-if="isShowModal" @callBackModal="callBackModal"/>
  </div>
</template>

<script>
import {mdbSideNav2, mdbBtn, waves, mdbNavbar, mdbNavItem, mdbNavbarNav,} from "mdbvue";
import adminNavigation from "../../assets/data/adminNavigation";
import {firebase} from "@/firebase/firebaseConfig";
import LogoutDialog from "@/views/therapist/myPage/LogoutDialog";

export default {
  name: "MasterView",
  components: {
    LogoutDialog,
    mdbSideNav2,
    mdbBtn,
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
  },
  data() {
    return {
      lock: false,
      show: true,
      collapsed: false,
      navigation: adminNavigation,
      email : this.$store.state.user.displayName,
      isShowModal : false
    };
  },
  methods: {
    selectItem() {
      if (!this.lock) {
        // this.collapsed = true
      }
    },

    collapseLock() {
      this.collapsed = !this.collapsed
      this.lock = !this.collapsed;
    },

    // logout() {
    //   const self = this;
    //   firebase.auth().signOut().then(() => {
    //     self.$router.replace('/master');
    //   }).catch((error) => {
    //     console.log(error);
    //   });
    // },
    callBackModal: function (index) {
      const self = this;
      if (index === 1) {
        self.isShowModal = false
      }else {
        self.isShowModal = false
        const logoutTime = new Date().getTime();
        localStorage.setItem('logoutTime', logoutTime.toString());
        firebase.auth().signOut()
            .then(() => {
              self.$router.replace('/master/login');
            })
      }
    },
  },
  mixins: [waves]
};
</script>

<style scoped>

.navbar i {
  cursor: pointer;
  color: white;
}

.main {
}

.main-contents {
  margin-top: 56px;
  padding-bottom: 56px;
  min-height: calc(100vh - 56px) ;
}

.p-1 img{
  width: 200px;
  padding-top: 56px;
}
</style>
